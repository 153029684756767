const { baseUrl } = require("../helpers/baseUrl");

export const techImages = [
    { img: `${baseUrl()}/assets/img/technology/1.svg` },
    { img: `${baseUrl()}/assets/img/technology/2.svg` },
    { img: `${baseUrl()}/assets/img/technology/3.svg` },
    { img: `${baseUrl()}/assets/img/technology/4.svg` },
    { img: `${baseUrl()}/assets/img/technology/6.svg` },
    { img: `${baseUrl()}/assets/img/technology/10.svg` },
    { img: `${baseUrl()}/assets/img/technology/11.svg` },
    { img: `${baseUrl()}/assets/img/technology/13.svg` },
    { img: `${baseUrl()}/assets/img/technology/14.svg` },
    { img: `${baseUrl()}/assets/img/technology/17.svg` },
    { img: `${baseUrl()}/assets/img/technology/19.svg` },
    { img: `${baseUrl()}/assets/img/technology/20.svg` },
    { img: `${baseUrl()}/assets/img/technology/21.svg` },
    { img: `${baseUrl()}/assets/img/technology/23.svg` },
    { img: `${baseUrl()}/assets/img/technology/30.svg` },
    { img: `${baseUrl()}/assets/img/technology/31.svg` },
    { img: `${baseUrl()}/assets/img/technology/32.svg` },
    { img: `${baseUrl()}/assets/img/technology/aspire.png` },
    { img: `${baseUrl()}/assets/img/technology/awscf.svg` },
    { img: `${baseUrl()}/assets/img/technology/busdumo.png` },
    { img: `${baseUrl()}/assets/img/technology/digitalocean.svg` },
    { img: `${baseUrl()}/assets/img/technology/getmail.png` },
    { img: `${baseUrl()}/assets/img/technology/ggg.png` },
    { img: `${baseUrl()}/assets/img/technology/googlecloud.svg` },
    { img: `${baseUrl()}/assets/img/technology/HTML.svg` },
    { img: `${baseUrl()}/assets/img/technology/hunybind.png` },
    { img: `${baseUrl()}/assets/img/technology/JS.svg` },
    { img: `${baseUrl()}/assets/img/technology/ThreeJS.svg` },
  ];