// import img from "next/img";
import React from "react";
import { ServiceTimeline } from "./magicui/service-timeline.tsx";
import { services } from "../data/services.js";

export function ServicesTimelineComponent() {
  return (
    <div className="w-full">
      <ServiceTimeline data={services} />
    </div>
  );
}
