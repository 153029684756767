import React from "react";
import { ServicesTimelineComponent } from "../components/ServiceTimelineComponent";
import { axiosClient } from "../helpers/axiosInstance";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

const Services = () => {

  const { register, handleSubmit, reset } = useForm();
  
  const handleServiceEnquiry = async (data) => {
    try {
      const response = await axiosClient.post("/service_enquiry/add", data);
      console.log(response);
      if (!response.data.status) {

        toast.error("Please try again!")
        return;
      }
      toast.success("Your enquiry has been submitted!");
      reset()
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      toast.error("Error submitting enquiry");
    }
  };

  return (
    <>
      <div className="my-5">
        <ServicesTimelineComponent />
      </div>
      <div className="bg-gray-200 -mb-[100px] pb-[100px] pt-5">
        <div className="w-full container">
          <h2 className="text-2xl font-bold mb-4 text-center">
            Ready to elevate your IT infrastructure?
          </h2>
          <div>
            <form onSubmit={handleSubmit(handleServiceEnquiry)} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 justify-center items-center gap-3">
              <div className="relative">
                <div className="absolute inset-y-0 left-4 flex items-center z-10">
                  <span className="bg-transparent pr-3 border-r border-gray-700">
                    <i className="fa-solid fa-user text-[#1f2471] text-lg"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-input pl-16 pr-4 py-3 rounded-lg shadow-md w-full focus:outline-none focus:ring-2"
                  maxLength="10"
                  id="name"
                  placeholder="Full Name"
                  name="name"
                  required
                  {...register("name")}
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-4 flex items-center z-10">
                  <span className="bg-transparent pr-3 border-r border-gray-700">
                    <i className="fa-solid fa-phone text-[#1f2471] text-lg"></i>
                  </span>
                </div>
                <input
                  type="number"
                  className="form-input pl-16 pr-4 py-3 rounded-lg shadow-md w-full focus:outline-none focus:ring-2"
                  maxLength="10"
                  id="phone"
                  placeholder="Phone No."
                  name="phone"
                  required
                  {...register("phone")}
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-4 flex items-center z-10">
                  <span className="bg-transparent pr-3 border-r border-gray-700">
                    <i className="fa-solid fa-envelope text-[#1f2471] text-lg"></i>
                  </span>
                </div>
                <input
                  type="email"
                  className="form-input pl-16 pr-4 py-3 rounded-lg shadow-md w-full focus:outline-none focus:ring-2"
                  maxLength="10"
                  id="email"
                  placeholder="Email"
                  name="email"
                  required
                  {...register("email")}
                />
              </div>
              <div className="relative">
                <select
                  required
                  {...register("service")}
                  name="service"
                  id="service"
                  className="form-select pr-4 py-3 rounded-lg shadow-md w-full"
                >
                  <option value="">-Select Services-</option>
                  <option value="Web Development">Web Development</option>
                  <option value="App Development">App Development</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Shopify">Shopify</option>
                  <option value="Enterprise resource planning">
                    Enterprise resource planning
                  </option>
                </select>
              </div>
              <div className="grid grid-cols-1 justify-center">
                <button
                  type="submit"
                  className="flex items-center justify-center h-12 w-32 bg-[#172554] rounded-full text-white font-bold transition-all duration-200 ease-linear hover:shadow-lg hover:transform hover:-translate-y-1"
                >
                  <span className="text-white">Submit</span>
                  <i className="fa-solid fa-arrow-right ml-2 text-lg"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
