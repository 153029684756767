import toast from "react-hot-toast";
import { axiosClient } from "../axiosInstance";

export const EnquiryApi = async (userData) => {
  try {
    const response = await axiosClient.post("/enquiry/add", userData);
    console.log(response.data);
    toast.success("Your enquiry has been submitted!")
    return response.data;
  } catch (error) {
    console.error("Error submitting enquiry:", error);
    toast.error("Error submitting enquiry");
    throw error;
  }
};
