export const metaData = [
    {
        title: "Data Analytics Course in Indore",
        metaTitle: "Data Analytics Course in Indore with Training & Certification",
        metaDescription: "Join MindCoders for top data analytics & business analyst courses in indore. Get expert training in data analytics with placement support.",
        metaKeywords: "data analytics course in indore, data analytics training in indore, business analyst course in indore",
    },
    {
        title: "React js Course in Indore",
        metaTitle: "Best ReactJS Certification and Training Course in Indore",
        metaDescription: "Best ReactJS certification and training courses in Indore.Get expert ReactJS training with placement options to enhance your skills and career prospects.",
        metaKeywords: "best react js certification course in indore, react js course in indore with placement, react js training course in indore",
    },
    {
        title: "Digital Marketing Classes in Indore",
        metaTitle: "Digital Marketing Classes in Indore With Internships",
        metaDescription: "Mindcoders offers you best Digital Marketing Course in indore at affordable fees with Internships and Placement Support",
        metaKeywords: "digital marketing classes in indore, digital marketing course in indore, digital marketing training course in indore, digital marketing course in indore with placement, digital marketing course at bhawarkua",
    },
    {
        title: "Mern Stack Developer Course in Indore",
        metaTitle: "Advanced Mern Stack Developer Training Course in Indore",
        metaDescription: "Mindcoders offers you best Mern Stack Website Development Course & Traning in indore at affordable fees with Internships and Placement support",
        metaKeywords: "Mern Stack Developer Training In Indore, Mern Stack Developer Certification In Indore, Mern Stack Development Course In Indore, Mern Stack Course In Indore, Mern Stack Training In Indore, Mern Stack Certification In Indore",
    },
    {
        title: "Java Full Stack Developer Course in Indore",
        metaTitle: "Java Full Stack Developer Course Bootcamp in Indore with Internships",
        metaDescription: "Register now in Full Stack Java Developer Course in indore with the Best training institute with Placement support and Internship",
        metaKeywords: "Java full stack developer course in indore, java full stack developer course, best java full stack development course in indore",
    },
    {
        title: "Master Data Science Course in Indore",
        metaTitle: "Learn Data Science in Indore with Placement Support",
        metaDescription: "Advanced Data science course with the best training institute in indore with Certification and Placement support",
        metaKeywords: "Data Science course in Indore,Data Science classes in Indore,Data Science training in Indore,Data Science certification in Indore,Data Science internship in Indore,Data Science certification in Indore,Best Data Science course in Indore",
    },
    {
        title: "DSA Course in Indore",
        metaTitle: "Data Structure with Algorithms Training Classes in Indore with Placement Support",
        metaDescription: "Mindcoders provides you best Data Structure with Algorithms (DSA) Classes & Training in indore with Certification and Internships",
        metaKeywords: "Data Structures and Algorithms (DSA) course in Indore, Data Structures and Algorithms (DSA) classes in Indore, Data Structures and Algorithms (DSA) training in Indore, Data Structures and Algorithms (DSA) Certification Course in Indore, Data Structures and Algorithms (DSA) internship in Indore, Data Structures and Algorithms (DSA) certification in Indore, Best Data Structures and Algorithms (DSA) course in Indore",
    },
    {
        title: "Java Programming Course in Indore",
        metaTitle: "Mastered Java Training Course in Indore with Placement Support",
        metaDescription: "Learn Java Programming Course with Mindcoders the Best training Centre in Indore with certification and Internships",
        metaKeywords: "Java programming course in Indore, Java programming classes in Indore, Java programming training in Indore, Java programming Certification Course in Indore, Java programming internship in Indore, Java programming certification in Indore, Best Java Programming Course in Indore",
    },
    {
        title: "Python programming Course in Indore",
        metaTitle: "Python Programming Course in indore with Placement Support",
        metaDescription: "Mindcoders brings the best Python programming Course in Indore with Certification and Internships",
        metaKeywords: "Python programming course in Indore, Python programming classes in Indore, Python Programming Training course in Indore, Our Python Programming Certification course in Indore, Python Programming internship in Indore, Python Programming certifications in Indore, Best Python Programming Course in Indore",
    },
    {
        title: "Backend Development Training in Indore",
        metaTitle: "Best Backend Development Course in Indore with Placement Support",
        metaDescription: "Mindcoders offers Backend Development course in Indore with Internship and Placement Support",
        metaKeywords: "Backend development Course in Indore, Backend development classes in Indore, Backend development training course, Backend development Certification course in Indore, Backend Development internship in Indore, Backend Development certification in Indore, Best Backend development course in Indore, Top Python Course in indore",
    },
    {
        title: "Python Fullstack Training Course in Indore",
        metaTitle: "Best Full Stack Python Course in Indore with Internship and Placement Support",
        metaDescription: "Learn Python Fullstack Course with Mindcoders the Best training Centre in Indore with certification and Internship",
        metaKeywords: "Python Full-Stack Development in Indore, Python Full-Stack Development Course, Python Full-Stack Development, Python Full Stack Development classes in Indore, Python Full Stack Development Training course in Indore, Python Full Stack Development Certification course in Indore, Python Full-stack development internship in Indore, Python Full-stack development certification in Indore, Best Python Full-stack development course in Indore, Top Python Course in indore",
    },
    {
        title: "Frontend Development Course in Indore",
        metaTitle: "Best Frontend Development Training Course in Indore with Internship and Placement Support",
        metaDescription: "Learn Frontend Development with Best Training Classes in Indore with Certification and Internship",
        metaKeywords: "Front-End Development course in Indore, Front-End Web Development classes in Indore, Our Front end Web Development course in Indore, Front-End Web Development certification course in Indore, Front-End Web Development internship, Front-End Web Development certification in Indore, Best Front-end web development course in Indore, Top web development course in indore",
    },
]