import React, { useEffect } from "react";
import "particles.js"; // Import the library
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";

const ParticlesComponent = ({ title }) => {
  const slug = window.location.pathname.split("/");
  console.log("slug", slug);

  let pagePath = slug[slug.length - 1]
    .split("_")
    .filter((item) => item !== "in" && item !== "indore")
    .join(" ");
  console.log("pagePath", pagePath);
  useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 500,
          },
        },
        color: {
          value: ["#aa73ff", "#f8c210", "#83d238", "#33b1f8"],
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#fff",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 1,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: false,
            speed: 400,
            size_min: 0.4,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 120,
          color: "#ffffff",
          opacity: 0.2,
          width: 1,
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: false,
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 4,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <div
      id="particles-js"
      className="flex align-items-center justify-center"
      style={{ height: "inherit" }}
    >
      <div className="position-absolute text-center w-[100vw]">
        {/* <h2 className="breadcrumb-text-white capitalize h2 ">{pagePath[1]}</h2> */}
        <h2>
          <ReactTyped
            className="breadcrumb-text-white w-96 capitalize h2"
            strings={[pagePath]}
            loop
            backSpeed={100}
            typeSpeed={100}
          />
        </h2>
        <div>
          <Link to="/" className="breadcrumb-text-white capitalize">
            {title}
          </Link>

          {slug.length >= 2 && (
            <>
              {" "}
              <i className="fa-solid fa-angle-right text-white mx-2"></i>{" "}
              <Link
                to={`/${slug[1]}`}
                className="breadcrumb-text-white capitalize"
              >
                {slug[1].replace(/_/g, " ")}
              </Link>
            </>
          )}
          {slug.length === 3 && (
            <>
              {" "}
              <i className="fa-solid fa-angle-right text-white mx-2"></i>{" "}
              <span className="breadcrumb-text-white capitalize">
                {pagePath}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticlesComponent;
