import React, { useState } from "react";
import Accordion from "./Accodion";
import { Link } from "react-router-dom";

const Tabs = ({ content }) => {
  console.log(content);

  const [activeTab, setActiveTab] = useState("description");

  return (
    <>
      <div className="content_wrap bg-slate-100 md:px-10 lg:px-20">
        <div className="overflow-hidden block container">
          <nav className="mt-8">
            <ul
              role="tablist"
              className="flex relative bg-blue-gray-50 bg-opacity-60 rounded-lg p-1 flex-col md:flex-row"
              style={{ height: "auto" }}
            >
              {["description", "content", "projects", "course-features", "corporate-training"].map(
                (tab) => (
                  <li
                    key={tab}
                    role="tab"
                    className={`flex items-center justify-center text-center w-full md:w-auto h-full relative bg-transparent py-2 px-4 text-blue-gray-900 antialiased text-base md:text-lg font-normal leading-relaxed select-none cursor-pointer ${
                      activeTab === tab ? "font-bold" : ""
                    }`}
                    data-value={tab}
                    onClick={() => setActiveTab(tab)}
                  >
                    <div className="z-20 text-inherit">
                      <div className="flex items-center gap-2 capitalize">
                        {tab.replace("-", " ")}
                      </div>
                    </div>
                    {activeTab === tab && (
                      <div
                        className="absolute inset-0 z-10 h-full bg-white rounded-md"
                        style={{
                          transform: "none",
                          transformOrigin: "50% 50% 0px",
                          boxShadow: "2px 2px 15px gray",
                        }}
                      />
                    )}
                  </li>
                )
              )}
            </ul>
          </nav>
          <div className="block w-full relative bg-transparent overflow-hidden mt-4">
            {activeTab === "description" && (
              <div
                role="tabpanel"
                className="w-full h-max text-gray-700 p-4 md:p-6 antialiased text-base md:text-lg font-light leading-relaxed"
                data-value="description"
                style={{
                  opacity: 1,
                  position: "relative",
                  zIndex: 2,
                  top: 0,
                  left: 0,
                }}
              >
                <h1 className="text-2xl md:text-3xl font-bold capitalize">
                  Overview
                </h1>
                <p className="my-4 text-justify">{content.description}</p>
              </div>
            )}
            {activeTab === "content" && (
              <div
                role="tabpanel"
                className="w-full h-max text-gray-700 p-4 md:p-6 antialiased text-base md:text-lg font-light leading-relaxed"
                data-value="content"
                style={{
                  opacity: 1,
                  position: "relative",
                  zIndex: 2,
                  top: 0,
                  left: 0,
                }}
              >
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row items-center gap-0 md:gap-5 lg:gap-10 xl:gap-10">
                <h1 className="text-2xl md:text-3xl font-bold capitalize">
                  Course Content 
                </h1>
                <Link className="bg-[#172554] lg:ml-3 text-white hover:scale-105 font-bold px-3 py-2 rounded-md" target="_blank" to={content.pdfLink}>Download Curriculum <i class="fa-solid ms-2 fa-file-arrow-down" style={{color: "#ffffff"}}></i></Link>
                </div>
                <p className="my-4 text-justify">
                  Before You Start Here Is everything that you need to know
                  about the Course. Below are the topics and concepts covered
                  under this course.
                </p>
                <ul className="w-full flex flex-col gap-4">
                  {content.content.map((item, index) => (
                    <li key={index} className="flex relative flex-col gap-2">
                      <span
                        className="absolute left-0 grid justify-center bg-transparent transition-opacity duration-200"
                        style={{
                          top: "34.2px",
                          width: "34.2px",
                          opacity: 1,
                          height: "calc(100% - 34.2px)",
                        }}
                      >
                        <span className="w-0.5 h-full bg-blue-gray-100" />
                      </span>
                      <div className="flex items-center gap-4">
                        <span className="w-max relative z-[2] flex-shrink-0 rounded-full overflow-hidden bg-gray-900 text-white p-1 lg:text-3xl sm-xl md:text-3xl">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth={0}
                            viewBox="0 0 1024 1024"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" />
                          </svg>
                        </span>
                        <h5 className="block antialiased tracking-normal text-xl font-semibold leading-snug text-blue-gray-900">
                          {item.label}
                        </h5>
                      </div>
                      <div className="flex gap-4 pb-8">
                        <span
                          className="pointer-events-none invisible h-full flex-shrink-0"
                          style={{ width: "34.2px" }}
                        />
                        <div>
                          <p className="block antialiased text-base leading-relaxed font-normal text-gray-600">
                            {item.value}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                
              </div>
            )}
            {activeTab === "projects" && (
              <div
                role="tabpanel"
                className="w-full h-max text-gray-700 p-4 md:p-6 antialiased text-base md:text-lg font-light leading-relaxed"
                data-value="projects"
                style={{
                  opacity: 1,
                  position: "relative",
                  zIndex: 2,
                  top: 0,
                  left: 0,
                }}
              >
                <h1 className="text-2xl md:text-3xl font-bold capitalize">
                  Projects
                </h1>
                <p className="my-4 text-justify">{content.projects}</p>
              </div>
            )}
            {activeTab === "course-features" && (
              <div
                role="tabpanel"
                className="w-full h-max text-gray-700 p-4 md:p-6 antialiased text-base md:text-lg font-light leading-relaxed"
                data-value="course-features"
                style={{
                  opacity: 1,
                  position: "relative",
                  zIndex: 2,
                  top: 0,
                  left: 0,
                }}
              >
                <h1 className="text-2xl md:text-3xl font-bold capitalize">
                  Course Features
                </h1>
                <ul className="w-full flex flex-col gap-3">
                  {content.courseFeatures.map((item, index) => (
                    <div
                      key={index}
                      className="flex gap-3 text-lg items-center"
                    >
                      <i className="fa-solid fa-circle-arrow-right"></i>
                      <li className="font-bold">{item}</li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {activeTab === "corporate-training" && (
              <div
                role="tabpanel"
                className="w-full h-max text-gray-700 p-4 md:p-6 antialiased text-base md:text-lg font-light leading-relaxed"
                data-value="corporate-training"
                style={{
                  opacity: 1,
                  position: "relative",
                  zIndex: 2,
                  top: 0,
                  left: 0,
                }}
              >
                <h1 className="text-2xl md:text-3xl font-bold capitalize">
                  Corporate Training
                </h1>
                <ul className="w-full flex flex-col gap-3">
                  {content.corporateTraining.map((item, index) => (
                    <div
                      key={index}
                      className="flex gap-3 text-lg items-center"
                    >
                      <i className="fa-solid fa-circle-arrow-right"></i>
                      <li className="font-bold">{item}</li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <Accordion faq={content.faq} />
    </>
  );
};

export default Tabs;
