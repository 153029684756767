import React from "react";
import Breadcrumb from "../components/Breadcrumb";

const WorkshopPage = () => {
  return (
    <>
      {/* workshop section start */}
      <section className="ptb-120">
        <div className="container overflow-x-hidden">
          <h1 data-aos="fade-right" data-aos-duration="1000" className="h1">
            Workshops <span className="text-info"> &amp; </span> Seminars{" "}
          </h1>
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
            <div data-aos="fade-right" data-aos-duration="1200" className="flex justify-center col-span-1 lg:col-span-1">
              <video
                loop
                muted
                autoPlay
                src={`${window.location.origin.toString()}/assets/img/workshops-img/workshop-video.mp4`}
                className="rounded-xl h-[450px]"
              />
            </div>
            <div className="col-span-1 lg:col-span-2">
              <h1 data-aos="fade-left" data-aos-duration="1000" className="h1">
                About our <span className=" text-info">workshops</span>{" "}
              </h1>
              <p data-aos="fade-left" data-aos-duration="1200">
                The "CodeMind Workshop" by Mind Coders is a one-day program
                focused on advancing coding skills and fostering a mindful,
                innovative mindset. Participants engage in practical coding
                challenges merged with mindfulness techniques, explore advanced
                algorithms and optimization strategies, and collaborate on
                coding projects. The workshop also delves into emerging
                technologies and ethical coding practices. Key takeaways include
                enhanced coding skills, improved focus through mindfulness,
                collaboration proficiency, and awareness of emerging tech and
                ethics.
              </p>
              <div data-aos="fade-left" data-aos-duration="1400">
                <div className="d-flex col-sm-12 mt-4 floating-one">
                  <div className="triangle-left" />
                  <div className="rectangle">Career Counselling</div>
                </div>
                <div className="d-flex mt-4 me-2 floating-two">
                  <div className="triangle-left" />
                  <div className="rectangle">Proper Guidence</div>
                </div>
                <div className="d-flex mt-4 floating-one">
                  <div className="triangle-left" />
                  <div className="rectangle">Meet Industry Experts</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* workshop section end */}

      {/* Gallery section start */}
      {/* Photo Grid start */}
      <section className="pb-20 overflow-x-hidden">
        <div data-aos="fade-up" data-aos-duration="1000" className="container">
          <h2 className="h2">
            <span className="text-info">#</span> workshops
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-4">
            <img
              src="assets/img/workshops-img/22.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/11.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/16.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/3.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/13.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/10.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/23.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
            <img
              src="assets/img/workshops-img/14.webp"
              alt="img"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      </section>

      {/* Photo Grid end */}
      {/* Gallery section end */}
    </>
  );
};

export default WorkshopPage;
