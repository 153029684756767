import React from "react";
import SwiperSection from "../components/swiper/Swiper";
// import { baseUrl } from "../helpers/baseUrl";
import { celebration, trip, turf } from "../data/lifeAtMindcodersImg";

const LifeAtMindcoders = () => {
  return (
    <>
      <section className="container overflow-x-hidden pt-20">
        <div className="text-center">
          <h1 className="h1">Life at Mindcoders</h1>
          <p>
            At <span className="text-color-navy font-bold">Mind-Coders</span>,
            we’re not just about delivering top-tier education; we’re committed
            to creating a workplace where our employees can grow and thrive. We
            believe in empowering our team with the resources and support they
            need to advance their careers and make a real impact in the tech
            industry. Our collaborative work environment encourages innovation,
            continuous learning, and professional development. We offer flexible
            work arrangements, ongoing training programs, and a culture that
            values work-life balance. Whether you’re an educator, developer, or
            part of our administrative team, you’ll find a fulfilling career
            path at Mind-Coders, where your contributions are recognized and
            your potential is limitless.
          </p>
        </div>
        <hr className="bg-blue-900 h-1 rounded-xl mx-4 sm:mx-6 md:mx-8 lg:mx-10 my-4 sm:my-5 md:my-6" />

        {/* Celebration */}
        <div className="grid overflow-x-hidden grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-8">
          <div data-aos="fade-right" data-aos-duration="1000">
            <h3 className="h3 text-color-navy">#Celebration</h3>
            <p>
              We celebrate achievements with company-wide events, including
              birthdays and festivals. These celebrations strengthen
              relationships and build a sense of accomplishment.
              <ul>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Regular recognition of milestones and successes.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Birthday celebrations for both students and employees.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Festive events that honor cultural traditions.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Celebrations that enhance camaraderie and team spirit.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Events that reinforce a strong community bond.
                </li>
              </ul>
            </p>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000">
            <SwiperSection data={celebration} />
          </div>
        </div>

        {/* Turf */}
        <div className="grid overflow-x-hidden grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 sm:grid-flow-dense gap-8 my-[60px]">
          <div data-aos="fade-left" data-aos-duration="1000" className="sm:order-1 lg:order-2">
            <h3 className="h3 text-color-navy">#Turf</h3>
            <p>
              Friendly competition through turf activities promotes teamwork and
              a healthy lifestyle. These events provide a fun and active way to
              connect with colleagues.
              <ul>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Engagement in sports and games for fun and health.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Encouragement of teamwork and collaboration.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Promotion of an active lifestyle within the organization.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Opportunities for employees to bond in a relaxed setting.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Activities that contribute to overall well-being and morale.
                </li>
              </ul>
            </p>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="sm:order-2 lg:order-1">
            <SwiperSection data={turf} />
          </div>
        </div>

        {/* Trip */}
        <div className="grid overflow-x-hidden grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-8">
          <div data-aos="fade-right" data-aos-duration="1000">
            <h3 className="h3 text-color-navy">#Trip</h3>
            <p>
              Exploring new places during trips allows our team to relax, bond,
              and gain fresh perspectives. These outings are an opportunity to
              step away from routine and experience new environments.
              <ul>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Organized trips for team bonding and relaxation.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Exposure to new places and experiences.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Opportunities to disconnect from work routines.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Enhancement of team unity through shared experiences.
                </li>
                <li>
                  <i class="fa-regular fa-circle-right text-[#160057] me-2"></i>
                  Contribution to a more relaxed and open-minded work culture
                </li>
              </ul>
            </p>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000">
            <SwiperSection data={trip} />
          </div>
        </div>
      </section>
    </>
  );
};

export default LifeAtMindcoders;
