import React, { useState, useRef } from "react";

const Accordion = (props) => {
  console.log(props.faq);

  const [selected, setSelected] = useState(null);
  const containerRefs = useRef([]);

  const toggleAccordion = (index) => {
    setSelected(selected === index ? null : index);
  };

  const handleRotate = (index) => {
    return selected === index ? "rotate-180" : "";
  };

  const handleToggle = (index) => {
    return selected === index
      ? { maxHeight: `${containerRefs.current[index].scrollHeight}px` }
      : { maxHeight: 0 };
  };

  const accordionData = props.faq;

  return (
    <div className="max-w-5xl px-2 mx-auto mt-5 tracking-wide md:px-4">
      <div className="flex justify-center text-2xl md:text-3xl">FAQ's</div>
      <div className="flex flex-col py-8 text-base md:text-lg leading-6 text-gray-800 gap-5">
        {accordionData.map((item, index) => (
          <div key={index} className="space-y-3">
            <div className="relative transition-all duration-700 border rounded-xl hover:shadow-2xl">
              <div
                onClick={() => toggleAccordion(index)}
                className="w-full p-4 text-left cursor-pointer"
              >
                <div className="flex text-color-navy underline items-center justify-between">
                  <span className="tracking-wide font-bold">
                    {item.category}
                  </span>
                  <span
                    className={`transition-transform duration-500 transform fill-current ${handleRotate(
                      index
                    )}`}
                  >
                    <svg
                      className="w-5 h-5 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </div>
              </div>
              <div
                ref={(el) => (containerRefs.current[index] = el)}
                style={handleToggle(index)}
                className="relative overflow-hidden transition-all duration-700 max-h-0"
              >
                {item.questions.map((question, index) => (
                  <div key={index}>
                    <div className="px-6 font-semibold pb-4 text-gray-600">
                      Ques.{index + 1}:- {question.que}
                    </div>
                    <div className="px-6 pb-4 text-gray-600">
                      {" "}
                      <span className="font-semibold">Ans.</span> {question.ans}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
