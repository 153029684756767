import React from 'react'
import Tabs from "../../components/Tabs";
import { dataAnalyticsCourseData } from "../../data/courseTabData";
import { dataAnalitics } from '../../data/courseCurriculamData';
import { Icons } from '../../data/animatedBeamIconsData';
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { Helmet } from 'react-helmet';
import {metaData} from "../../data/metaData.js"

const DataAnaliticsPage = () => {

  const currentMetaData = metaData.find(meta => meta.title.includes("Data Analytics"));

  return (
    <>
    <Helmet>
      <title>{currentMetaData.title}</title>
      <meta name="title" content={currentMetaData.metaTitle} />
      <meta name="description" content={currentMetaData.metaDescription} />
      <meta name="keywords" content={currentMetaData.metaKeywords} />
    </Helmet>
        {/*hero section start*/}
        <section className="d-flex align-items-center position-relative">
          <div className="container ">
            <div className="row mt-5 justify-content-between align-items-center">
              <div className="col-lg-6">
                <div className="hero-content-wrap d-flex gap-2">
                  <div className="divider">
                    <span className="saperater" />
                  </div>
                  <div>
                    <h1 className="h1 course-heading">Data Analytics Course in Indore</h1>
                    <p className="course-overview">
                    "Master the Data Analytics Course in Indore at MindCoders, designed for aspiring business analysts and data enthusiasts. Gain essential skills in data visualization, statistical analysis, and data-driven decision-making through hands-on projects that will help you to mirror the real-world scenarios. Our Business Analyst Course in Indore provides the practical experience and knowledge you need to advance your data analytics career in indore. Whether you're Beginner or enhancing your skills, join us at MindCoders and turn data into actionable insights for business success."
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons}/>
                </div>
              </div>
            </div>
            <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {dataAnalitics.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>{topic}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
          </div>
        </section>
        {/*hero section end*/}

        <Tabs content={dataAnalyticsCourseData} />
    </>
  );
};

export default DataAnaliticsPage;