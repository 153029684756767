import React from "react";
import "./CourseCard.css";
import { Link } from "react-router-dom";

const CourseCard = ({
  title,
  img,
  url,
}) => {
  return (
    // <div className="course_card">
    //   <Link to={url}>
    //     <div
    //       className="top-section"
    //       style={{ background: `url(${img})`, backgroundSize: "cover" }}
    //     >
    //       <div className="border" />
    //       <div className="icons">
    //         <div className="logo">
    //           <h6 className="text-white">Advance </h6>
    //         </div>
    //       </div>
    //     </div>
    //   </Link>
    //   <div className="bottom-section">
    //     <span className="title">{title}</span>
    //     <div className="row row1">
    //       <div className="item">
    //         <i className="fa fa-calendar" aria-hidden="true" />
    //         <span className="big-text">{duration}</span>
    //       </div>
    //       <div className="item">
    //         <i className="fa-solid fa-graduation-cap" />
    //         <span className="big-text">{classroom}</span>
    //       </div>
    //       <div className="item">
    //         <i className="fa-solid fa-graduation-cap" />
    //         <span className="big-text">{online}</span>
    //       </div>
    //     </div>
    //     <div>
    //       <ul>
    //         {curriculam &&
    //           curriculam.map((info, index) => <li key={index}>{info}</li>)}
    //       </ul>
    //     </div>
    //   </div>
    // </div>

    <>
      <div className="bg-blue-950 p-3 rounded-lg">
        <img src={img} alt="course logo" />
        <h6 className="text-white h5 mt-3">{title}</h6>
        <Link to={url} className="flex items-center text-yellow-400 hover:scale-105 hover:translate-x-2 hover:text-yellow-500">
          <span className="me-2 text-lg font-bold ">More info</span>{" "}
          <i class="fa-solid fa-arrow-right-long"></i>
        </Link>
      </div>
    </>
  );
};

export default CourseCard;
