import React from "react";
import { react } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { reactCourseData } from "../../data/courseTabData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const ReactJsPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("React js")
  );
  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">React js Course in Indore</h1>
                  <p className="course-overview">
                  At MindCoders, we deliver an industry-driven ReactJS Development Course in Indore, offering certifications, internships, and placement support. Nestled in Indore’s booming tech landscape, our course is taught by industry professionals who grasp the local market's unique needs and opportunities. Covering both front-end and back-end development, including ReactJS, Redux, and Node.js, our program ensures you gain practical experience in Indore’s expanding tech industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {react.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}

      <Tabs content={reactCourseData} />
    </>
  );
};

export default ReactJsPage;
