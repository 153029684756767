import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CarouselImg } from "../data/carouselImgs";

const Carousel = () => {
  return (
    <section>
      <Swiper
        className="mySwiper"
        slidesPerView={1}
        spaceBetween={32}
        modules={[Autoplay, Pagination]}
        loop={true}
        centeredSlides={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 6000, disableOnInteraction: false }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
        }}
      >
        {CarouselImg.map((testimonial) => (
          <SwiperSlide className="" key={testimonial.id}>
            <img src={testimonial?.image} loading="lazy" className="w-full xl:aspect-[3/1] xl:object-cover lg:aspect-[3/1] lg:object-cover animate__animated animate__backInRight" alt="img" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination top-10"></div>
    </section>
  );
};

export default Carousel;
