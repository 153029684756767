import { testimonialData } from "../data/testimonialData.js";
import { cn } from "../lib/utils.ts";
import Marquee from "./magicui/marquee.tsx";

const firstRow = testimonialData.slice(0, testimonialData.length / 2);
const secondRow = testimonialData.slice(testimonialData.length / 2);

const ReviewCard = ({ img, name, rating, body }) => {
  return (
    <figure
      className={cn(
        "relative h-fit w-full max-w-[500px] cursor-pointer overflow-hidden rounded-xl border p-4",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]"
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <ul className="flex">
            {Array(rating)
              .fill()
              .map((_, i) => (
                <li key={i} className="text-info">
                  <i className="fas fa-star" />
                </li>
              ))}
          </ul>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};

export function VerticalReviews() {
  return (
    <div className="relative flex h-[500px] sm:h-[400px] md:h-[600px] flex-col md:flex-row items-center justify-center overflow-hidden rounded-lg bg-background">
      <Marquee pauseOnHover vertical className="hidden md:flex [--duration:30s] sm:[--duration:40s] md:[--duration:50s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.rating} {...review} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover vertical className="hidden md:flex [--duration:20s] sm:[--duration:30s] md:[--duration:40s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.rating} {...review} />
        ))}
      </Marquee>
      <Marquee pauseOnHover vertical className="flex md:hidden [--duration:30s]">
        {testimonialData.map((review) => (
          <ReviewCard key={review.rating} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-white dark:from-background"></div>
    </div>
  );
}
