import React, { useEffect } from "react";
import "particles.js"; // Import the library
import { Link, useLocation } from "react-router-dom";
import ParticlesComponent from "./Partical";
const Breadcrumb = () => {
  const location = useLocation();
  const pagePath = location.pathname.split("/").filter(Boolean);

  const pageTitle =
    pagePath.length > 0
      ? decodeURIComponent(pagePath[pagePath.length - 1].replace('-', " "))
      : "Home";

  if (location.pathname === "/") {
    return null;
  }

  return (
    <section className="breadcrumb-section bg-black">
      <div className="breadcrumb-option set-bg overflow-hidden">
        <ParticlesComponent title={"Home"} />
      </div>
    </section>
  );
};

export default Breadcrumb;
