import React from "react";
import { dsaData } from "../../data/courseTabData";
import { dsa } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { Icons } from "../../data/animatedBeamIconsData";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const DsaPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("DSA Course")
  );
  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="p-120 d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">DSA Course in Indore</h1>
                  <p className="course-overview">
                    The Data Structures with Algorithms (DSA) course in indore
                    is designed to build a strong foundation in problem-solving
                    and computational thinking. This course covers key data
                    structures like arrays, linked lists, stacks, queues, trees,
                    and graphs, along with essential algorithms for searching,
                    sorting, and optimization. Through hands-on coding exercises
                    and real-world applications, you will develop the skills
                    needed to write efficient and scalable code, making this
                    course perfect for anyone preparing for technical interviews
                    or looking to enhance their programming expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                {/* <img
                  className="course-image"
                  src={`${baseUrl()}/assets/img/courses-logo/dsa-logo.png`}
                  alt="img"
                  loading="lazy"
                /> */}
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {dsa.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}

      <Tabs content={dsaData} />
    </>
  );
};

export default DsaPage;
