import React from "react";
import { mern } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { mernData } from "../../data/courseTabData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const MERNPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Mern Stack Developer")
  );
  return (
    <>
    <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="flex align-items-center position-relative">
        <div className="container ">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Mern Stack Developer Course in Indore
                  </h1>
                  <p className="course-overview">
                    Master MERN Stack Developer course in Indore is designed to
                    equip you with the complete skill set required to build
                    powerful, full-stack web applications using MongoDB,
                    Express.js, React.js, and Node.js (MERN). This hands-on
                    program with best training classes in indore takes you from
                    the basics to advanced topics, ensuring you have the
                    expertise needed to succeed in the fast-growing field of web
                    development. Key features of our course are Comprehensive
                    Curriculum, Real-World Projects, Expert
                    Instructors,Internship & Placement ,Flexible Learning
                    Options Whether you're a beginner or an experienced
                    developer looking to expand your knowledge, this course
                    offers in-depth training on the MERN stack, covering
                    front-end and back-end development in indore You will work
                    on real-world projects, develop scalable applications, and
                    master the tools and techniques essential to become a
                    proficient MERN stack developer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap me-2">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {mern.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>

      {/*hero section end*/}
      <Tabs content={mernData} />
    </>
  );
};

export default MERNPage;
