const JobCard = ({ title, experience, skills }) => (
  <div className="card_job bg-white p-4 shadow-lg  transition-transform duration-300 ease-in-out hover:scale-105">
    <div className="content">
      <p className="heading text-lg font-semibold text-gray-900">{title}</p>
      <div className="para_content text-gray-700">
        <p className="exp">
          <span className="career-icon text-blue-500">
            <i className="fa fa-briefcase" />
          </span>{" "}
          Experience: {experience}
        </p>
      </div>
      <ul className="ul_contant mt-2 list-disc pl-5">
        <b className="text-blue-500">Skill Set:</b>
        {skills.map((skill, index) => (
          <li className="li_content" key={index}>{skill}</li>
        ))}
      </ul>
      <a href="#job-form">
        <button className="btnn bg-blue-500 text-white py-2 px-4 rounded mt-2 hover:bg-blue-600 transition">
          Apply Now
        </button>
      </a>
    </div>
  </div>
);

export default JobCard;
