import React, { useContext } from "react";
import BlogCard from "../components/cards/BlogCard";
import { BlogContext } from "../context/blog/BlogContext";

const BlogPage = () => {

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sub-heading mt-5">
                <h1 className="h1 text-color-navy text-center">Latest Blog</h1>
              </div>
            </div>
          </div>
          <div className="my-5">
            <BlogCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPage;
