import React, { useContext } from "react";
import BlogPageLayout from "../../components/layout/BlogPageLayout";
import { baseUrl } from "../../helpers/baseUrl";
import { BlogContext } from "../../context/blog/BlogContext";
import { useParams } from "react-router-dom";

const BlogDetailedPage = () => {
  const { blogs } = useContext(BlogContext);
  // console.log(blogs);
  const idName = useParams().id;
  // console.log(idName);

  const blogData = blogs.find((item) => item.name === idName);
  console.log(blogData);

  return (
    <BlogPageLayout>
      {/*main section start*/}
      <section className="home-blog-section ptb-40">
        {/* {blogs.map((blog, index) => ( */}
        <div className="py-8">
          <div className="">
            <div>
              <h3 className="h3">{blogData.blog_heading}</h3>
              <p>{blogData.blog_description}</p>
            </div>
            <div className="mt-2">
              <img
                src={blogData.blog_image}
                loading="lazy"
                alt="What is MERN?"
              />
            </div>
          </div>

          {blogData.blog_content.map((content, index) => (
            <div key={index} className="row py-6">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <h3 className="h3">{content.heading}</h3>
                <img src={content.poster} alt="img" loading="lazy" />
                <p>{content.description}</p>
                <dl className="list-disc">
                  {content.points.map((point, index) => (
                    <div key={index}>
                      <dt className="text-lg">{point.point}</dt>
                      <dd>{point.des}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          ))}
        </div>
        {/* // ))} */}
      </section>
      {/*main section end*/}
    </BlogPageLayout>
  );
};

export default BlogDetailedPage;
