import { baseUrl } from "../helpers/baseUrl";

export const workshopImg = [
  `${baseUrl()}/assets/img/workshops-img/4.webp`,
  `${baseUrl()}/assets/img/workshops-img/23.webp`,
  `${baseUrl()}/assets/img/workshops-img/1.webp`,
  `${baseUrl()}/assets/img/workshops-img/3.webp`,
  `${baseUrl()}/assets/img/workshops-img/2.webp`,
  `${baseUrl()}/assets/img/workshops-img/14.webp`,
  `${baseUrl()}/assets/img/workshops-img/5.webp`,
  `${baseUrl()}/assets/img/workshops-img/22.webp`,
  `${baseUrl()}/assets/img/workshops-img/9.webp`,
];