import { baseUrl } from "../helpers/baseUrl";

export const mainPlacement = [
    {
      id: 1,
      image: `${baseUrl()}/assets/img/placements-img/main/kunal.png`,
    },
    {
      id: 2,
      image: `${baseUrl()}/assets/img/placements-img/main/jayesh.png`,
    },
    {
      id: 3,
      image: `${baseUrl()}/assets/img/placements-img/main/yogesh.jpg`,
    },
    {
      id: 4,
      image: `${baseUrl()}/assets/img/placements-img/main/ayush.png`,
    },
    {
      id: 5,
      image: `${baseUrl()}/assets/img/placements-img/main/dinky.png`,
    },
  ];

 export const placementsData = [
    {
      id: 1,
      name: "Akshay Patel",
      image: `${baseUrl()}/assets/img/placements-img/Akshay.webp`,
      designation: "MERN Stack Developer",
    },
    {
      id: 2,
      name: "Md.Arif Hussain",
      image: `${baseUrl()}/assets/img/placements-img/Arif.webp`,
      designation: "Angular Developer",
    },
    {
      id: 3,
      name: "Rahul Kumar",
      image: `${baseUrl()}/assets/img/placements-img/Rahul.webp`,
      designation: "Software Developer",
    },
    {
      id: 4,
      name: "Surbhi Rim",
      image: `${baseUrl()}/assets/img/placements-img/Surbhi.webp`,
      designation: "Software Developer",
    },
    {
      id: 5,
      name: "Aamir Khan",
      image: `${baseUrl()}/assets/img/placements-img/Aamir.webp`,
      designation: "Software Developer",
    },
  ];