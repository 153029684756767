import { baseUrl } from "../helpers/baseUrl"

export const celebration = [
    {
        id: 1,
        image: `${baseUrl()}/assets/img/GalleryImg/G7.webp`,
    },
    {
        id: 2,
        image: `${baseUrl()}/assets/img/GalleryImg/G8.webp`,
    },
    {
        id: 3,
        image: `${baseUrl()}/assets/img/GalleryImg/G4.webp`,
    },
    {
        id: 4,
        image: `${baseUrl()}/assets/img/GalleryImg/G9.webp`,
    },
    {
        id: 5,
        image: `${baseUrl()}/assets/img/GalleryImg/G10.webp`,
    },  
]

export const trip = [
    {
        id: 1,
        image: `${baseUrl()}/assets/img/GalleryImg/t1.webp`,
    },
    {
        id: 2,
        image: `${baseUrl()}/assets/img/GalleryImg/t2.webp`,
    },
    {
        id: 3,
        image: `${baseUrl()}/assets/img/GalleryImg/t3.webp`,
    },
    {
        id: 4,
        image: `${baseUrl()}/assets/img/GalleryImg/t4.webp`,
    },
    {
        id: 5,
        image: `${baseUrl()}/assets/img/GalleryImg/t5.webp`,
    },
    {
        id: 6,
        image: `${baseUrl()}/assets/img/GalleryImg/t6.webp`,
    },
]


export const turf = [
    {
        id: 1,
        image: `${baseUrl()}/assets/img/GalleryImg/tf1.webp`,
    },
    {
        id: 2,
        image: `${baseUrl()}/assets/img/GalleryImg/tf2.webp`,
    },
    {
        id: 3,
        image: `${baseUrl()}/assets/img/GalleryImg/tf4.webp`,
    },
    {
        id: 4,
        image: `${baseUrl()}/assets/img/GalleryImg/tf3.webp`,
    },
    {
        id: 5,
        image: `${baseUrl()}/assets/img/GalleryImg/tf5.webp`,
    },
]