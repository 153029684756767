import React, { useEffect, useState } from "react";
import { baseUrl } from "../helpers/baseUrl";
import ContactForm from "./ContactForm";

const EnquiryForm = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 3000);
  }, []);

  return (
    <div className="flex items-center justify-center">
      <div>
        <button
          id="enquiryModel"
          onClick={() => setShowModal(true)}
          className="stick-btn modelshow"
        >
          Enquiry Now
        </button>

        {showModal && (
          <>
            {/* Background overlay */}
            <div
              onClick={() => setShowModal(false)}
              className="fixed inset-0 bg-gray-500 transition-opacity duration-300 opacity-75"
              aria-hidden="true"
            ></div>

            {/* Modal */}
            <div
              data-aos="zoom-in"
              className="fixed z-[999] backdrop-blur-sm inset-0 flex items-center justify-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="enquiryModalLabel"
            >
              <div className="bg-white rounded-lg overflow-auto shadow-xl transform transition-all max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] h-[90vh] sm:h-auto">
                <div className="p-3 z-[999] absolute right-0">
                  <i
                    onClick={() => setShowModal(false)}
                    className="fa-solid scale-125 text-red-600 fa-xmark fa-xl cursor-pointer"
                    aria-label="Close"
                  ></i>
                </div>
                <div className="flex flex-col md:flex-row gap-4 p-4 h-full overflow-auto">
                  <div className="w-full md:w-1/2">
                    <img
                      className="w-full h-auto"
                      src={`${baseUrl()}/assets/img/enquiry-form/call_us.gif`}
                      alt="gif"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EnquiryForm;
