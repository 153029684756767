import { baseUrl } from "../helpers/baseUrl";

export const Icons = [
    {
        name: 'backend_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/backend.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/python.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/django.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/npm.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/pngwing.com.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/express.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/api.png`,
    },
    {
        name: 'dsa_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/dsa.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/3.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/4.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/5.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/6.png`,
    },
    {
        name: 'data_analytics_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/da6.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/da.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/da1.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/da3.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/da2.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/da4.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/da5.png`,
    },
    {
        name: 'data_science_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/ds.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/ds1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/ds2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/ds3.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/ds4.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/ds5.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/ds6.png`,
    },
    {
        name: 'digital_marketing_course_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/dm.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/dm1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/dm2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/dm3.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/dm4.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/dm5.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/dm6.png`,
    },
    {
        name: 'frontend_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/f.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/f1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/f2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/f3.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/f4.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/f5.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/f6.png`,
    },
    {
        name: 'java_fullstack_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/j.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/j1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/j2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/f1.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/f4.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/f.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/f6.png`,
    },
    {
        name: 'java_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/j.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/j1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/j2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/jp.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/jp1.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/jp2.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/jp3.png`,
    },
    {
        name: 'mern_stack_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/mern.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/f.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/f5.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/f6.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/f3.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/mern1.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/mern2.png`,
    },
    {
        name: 'python_fullstack_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/python.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/f.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/f5.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/f6.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/j1.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/django.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/mern2.png`,
    },
    {
        name: 'python_course_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/python.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/django.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/py1.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/py2.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/jp.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/jp2.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/jp3.png`,
    },
    {
        name: 'react_js_training_in_indore',
        iconCenter: `${baseUrl()}/assets/img/animated-beam-icons/f.png`,
        iconLeft1: `${baseUrl()}/assets/img/animated-beam-icons/f1.png`,
        iconLeft2: `${baseUrl()}/assets/img/animated-beam-icons/f2.png`,
        iconLeft3: `${baseUrl()}/assets/img/animated-beam-icons/f3.png`,
        iconRight1: `${baseUrl()}/assets/img/animated-beam-icons/f4.png`,
        iconRight2: `${baseUrl()}/assets/img/animated-beam-icons/f5.png`,
        iconRight3: `${baseUrl()}/assets/img/animated-beam-icons/f6.png`,
    },
]