import React from "react";
import { frontend } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { frontendData } from "../../data/courseTabData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const FrontEndPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Frontend Development")
  );
  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/* Main section start */}
      <section className="d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Frontend Development Course in Indore
                  </h1>
                  <p className="course-overview p">
                    Mindcoder's Front-End Development course in Indore focus on
                    creating engaging and user-friendly interfaces for web
                    applications. You’ll learn the core technologies of web
                    development: HTML, CSS, and JavaScript, MySQL, PHP along
                    with modern frameworks and libraries like React, Angular, or
                    Vue.js. This Web Development Front-end Development course
                    covers key topics such as responsive design, user experience
                    (UX), and performance optimization. Learn Front-end Web
                    Development Course in Indore with with Industry Expert
                    teachers and get Internships and Placement Support .Through
                    hands-on projects and real-world scenarios, you’ll develop
                    the skills to build visually appealing and functional
                    websites and applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {frontend.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>

      <Tabs content={frontendData} />
    </>
  );
};

export default FrontEndPage;
