import React from "react";
import { pythonFullStack } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { pythonFullStackData } from "../../data/courseTabData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const PythonFullstackPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Python Fullstack Training")
  );
  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Python Fullstack Training Course in Indore
                  </h1>
                  <p className="course-overview">
                    The Python Full-Stack Developer course in Indore offers
                    comprehensive training in both front-end and back-end
                    development using Python. You’ll master front-end
                    technologies such as HTML, CSS, and JavaScript, and back-end
                    frameworks like Django or Flask. This Python course in
                    Indore covers essential topics including web development
                    principles, RESTful APIs, database management with SQL and
                    NoSQL, and deployment practices. Through hands-on projects
                    and real-world applications with Internship and Placement
                    support, you’ll gain the skills to build dynamic,
                    full-featured web applications from start to finish.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {pythonFullStack.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}

      <Tabs content={pythonFullStackData} />
    </>
  );
};

export default PythonFullstackPage;
