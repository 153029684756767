const Path = {
    HOME: "/",
    ABOUT:"/about",
    CONTACT:"/contact",
    COURSES:"/courses",
    CAREER:"/career",
    PLACEMENT:"/placement",
    WORKSHOP:"/workshop",
    JOBS: "/jobs",
    TERMS_CONDITIONS: '/terms_conditions',
    LIFE_AT_MINDCODERS: '/life_at_mindcoders',
    PRIVACY_POLICY: '/privacy_policy',
    COURSE_DSA:"/courses/dsa_course_in_indore",
    COURSE_PYTHON:"/courses/python_course_in_indore",
    COURSE_PYTHON_FULLSTACK:"/courses/python_fullstack_course_in_indore",
    COURSE_JAVA:"/courses/java_course_in_indore",
    COURSE_JAVA_FULLSTACK:"/courses/java_fullstack_course_in_indore",
    COURSE_FRONTEND:"/courses/frontend_course_in_indore",
    COURSE_BACKEND:"/courses/backend_course_in_indore",
    COURSE_MERN:"/courses/mern_stack_course_in_indore",
    COURSE_REACT:"/courses/react_js_training_in_indore",
    COURSE_DIGITALMARKETING:"/courses/digital_marketing_course_indore",
    COURSE_DATA_ANALITICS:"/courses/data_analytics_course_in_indore",
    COURSE_DATA_SCIENCE:"/courses/data_science_course_in_indore",
    INTERNSHIP: "/internship",
    BLOG:"/blog",
    BLOG_DETAILED: "/blog/:id",
    SERVICES: "/services",
    SERVICES_APP_DEVELOPMENT: "/services/app_development",
    SERVICES_WEB_DEVELOPMENT: "/services/web_development",
    SERVICES_DIGITAL_MARKETING: "/services/digital_marketing",
    SERVICES_SHOPIFY: "/services/shopify",
    SERVICES_ERP: "/services/enterprise_resource_planning",
  };
  export default Path;
  