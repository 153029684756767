import { baseUrl } from "../helpers/baseUrl";

export const CarouselImg = [
    {
        id: 1,
        image: `${baseUrl()}/assets/img/carousel-img/1.png`,
      },
      {
        id: 2,
        image: `${baseUrl()}/assets/img/carousel-img/2.png`,
      },
      {
        id: 3,
        image: `${baseUrl()}/assets/img/carousel-img/3.png`,
      },
      {
        id: 4,
        image: `${baseUrl()}/assets/img/carousel-img/4.png`,
      },
      {
        id: 5,
        image: `${baseUrl()}/assets/img/carousel-img/5.png`,
      },
]