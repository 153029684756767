import React from "react";
import { backend } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { backendData } from "../../data/courseTabData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { Icons } from "../../data/animatedBeamIconsData.js";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const BackendPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Backend Development")
  );

  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Backend Development Training in Indore
                  </h1>
                  <p className="course-overview">
                    The Backend Development course in Indore focuses on building
                    robust server-side applications and services. In Backend
                    development Classes learn to work with server-side languages
                    and frameworks such as Node.js, Python, Ruby on Rails, or
                    Java, and gain skills in database management using SQL and
                    NoSQL databases. The Backend Development Training course in
                    Indore covers essential topics like API development, server
                    architecture, authentication, and security. Through hands-on
                    projects and real-world scenarios, you’ll develop the
                    expertise to create scalable, efficient backend systems that
                    support modern web and mobile applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
                {/* <img
                  className="course-image"
                  src={`${window.location.origin.toString()}/assets/img/courses-logo/backend-logo.webp`}
                  alt="img"
                  loading="lazy"
                /> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {backend.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}

      <Tabs content={backendData} />
    </>
  );
};

export default BackendPage;
