import { useState, useTransition } from "react";

export default function ServiceTab({ ourServices, whyUs, benefits }) {
  const [activeTab, setActiveTab] = useState("tab1");
  const [isPending, startTransition] = useTransition();

  const handleTabChange = (tab) => {
    startTransition(() => {
      setActiveTab(tab);
    });
  };

  const renderContent = () => {
    switch (activeTab) {
      case "tab1":
        return (
          <div className="space-y-4">
            {ourServices.map((service, index) => (
              <div key={index}>
                <h2 className="text-xl font-bold">{service.name}</h2>
                <p>{service.content}</p>
              </div>
            ))}
          </div>
        );
      case "tab2":
        return (
          <div className="space-y-4">
            {whyUs.map((reason, index) => (
              <div key={index}>
                <h2 className="text-xl font-bold">{reason.name}</h2>
                <p>{reason.content}</p>
              </div>
            ))}
          </div>
        );
      case "tab3":
        return (
          <div className="space-y-4">
            {benefits.map((benefit, index) => (
              <div key={index}>
                <h2 className="text-xl font-bold">{benefit.name}</h2>
                <p>{benefit.content}</p>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-3xl">
      <div className="grid grid-cols-3 gap-3 mb-4">
        <button
          className={`${
            activeTab === "tab1"
              ? "bg-blue-950 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
              : "border border-input bg-background text-center rounded-lg hover:bg-accent hover:text-accent-foreground"
          } py-2`}
          
          style={{
            transitionDelay: activeTab === "tab1" ? "100ms" : "0ms",
          }}
          onClick={() => handleTabChange("tab1")}
        >
          Our Services
        </button>
        <button
          className={`${
            activeTab === "tab2"
              ? "bg-blue-950 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
              : "border border-input bg-background text-center rounded-lg hover:bg-accent hover:text-accent-foreground"
          } py-2`}
          style={{
            transitionDelay: activeTab === "tab2" ? "100ms" : "0ms",
          }}
          onClick={() => handleTabChange("tab2")}
        >
          Why Us
        </button>
        <button
          className={`${
            activeTab === "tab3"
              ? "bg-blue-950 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
              : "border border-input bg-background text-center rounded-lg hover:bg-accent hover:text-accent-foreground"
          } py-2`}
          style={{
            transitionDelay: activeTab === "tab3" ? "100ms" : "0ms",
          }}
          onClick={() => handleTabChange("tab3")}
        >
          Benefits
        </button>
      </div>
      <div className="border rounded-lg p-4">{renderContent()}</div>
    </div>
  );
}
