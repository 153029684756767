import React, { useEffect, useState } from "react";
import { baseUrl } from "../helpers/baseUrl";

const Preloader = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          document.getElementById("preloader").style.display = "none";
          return 100;
        }
        return prev + 1;
      });
    }, 30); // Adjust the speed by changing the interval time

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      id="preloader"
      className="fixed inset-0 flex items-center justify-center bg-gray-100 z-50"
    >
      <div className="text-center">
        <img
          src={`${baseUrl()}/assets/img/fevicon 01.webp`}
          alt="logo"
          className="w-24 h-24 mb-4"
          style={{ opacity: percentage / 100 }}
          loading="lazy"
        />
        <div className="w-full bg-gray-200 h-2 rounded-full relative overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-blue-600 transition-all"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="text-lg font-bold mt-2">{percentage}%</div>
      </div>
    </div>
  );
};

export default Preloader;
